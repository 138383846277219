import * as React from 'react'
import {
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  useDataProvider,
} from 'react-admin'
import { parse } from 'query-string'
import { DateTime } from 'luxon'

export const CreditcardParticipantsCreate = (props) => {
  const dataProvider = useDataProvider()
  const { creditcardId: creditcardId_string } = parse(props.location.search)
  const creditcard_id = creditcardId_string
    ? parseInt(creditcardId_string, 10)
    : ''
  const redirect = creditcard_id
    ? `creditcard/${creditcard_id}/show/participants`
    : false
  const transform = async (data) => {
    const resp = await dataProvider.getOne('creditcard', {
      id: creditcard_id,
    })
    const creditcard = resp?.data
    const validity_splitted = creditcard?.details?.validity_period
      ? creditcard.details.validity_period.split(',')
      : []
    const interval = validity_splitted[0]
    const type = validity_splitted[1]
    let valid_until = undefined
    if (interval && type) {
      valid_until = DateTime.local().plus({ [type]: interval })
    }
    return {
      ...data,
      details: {
        comment: `manually created for ${data.comment}`,
        status: 'active',
        remaining: creditcard?.details?.times_to_use,
        valid_until,
      },
    }
  }

  return (
    <Create {...props} transform={transform} redirect={redirect}>
      <SimpleForm initialValues={{ creditcard_id }}>
        <NumberInput label="Deelnemer ID" source="participant_id" />
        <NumberInput
          label="Strippenkaart ID"
          source="creditcard_id"
          disabled={!!creditcard_id}
        />
        <TextInput label="Handmatig aangemaakt voor" source="comment" />
      </SimpleForm>
    </Create>
  )
}
