import * as React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
} from 'react-admin'
import { CurrencyField } from '../components/Number'
import { FullNameField, EventTypeField } from '../components/Text'
import { DateLongField } from '../components/Date'

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export const EventList = (props) => (
  <List
    filters={<EventFilter />}
    title="Events"
    {...props}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Naam" />
      <DateLongField source="startDate" label="Datum" />
      <CurrencyField source="cost" label="Prijs" />
      <CurrencyField source="fee" label="Fee" />
      <ReferenceField
        label="Organisator"
        source="ownerId"
        reference="participant"
        link="show">
        <FullNameField firstName="firstName" lastName="lastName" />
      </ReferenceField>
      <ReferenceField
        label="Activiteit"
        source="activityID"
        reference="activity"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Type"
        source="event_typeID"
        reference="event_type"
        link={false}>
        <EventTypeField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)
