import * as React from 'react'
import {
  Edit,
  SimpleForm,
  DateTimeInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

export const BookingEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <DateTimeInput source="startDateTime" label="Datum en starttijd" />
      <DateTimeInput source="endDateTime" label="Datum en eindtijd" />
      <TextInput label="Prijs" source="cost" />
      <ReferenceInput
        perPage={9999}
        sort={{ field: 'name', order: 'ASC' }}
        label="Locatie"
        source="commercial_location_id"
        reference="commercial_location">
        <AutocompleteInput
          shouldRenderSuggestions={(value) => value.length >= 2}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
