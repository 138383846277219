import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
} from 'react-admin'
import { storage } from './storage'
import { config } from './config'

const createAuthProvider = (loginApiUrl, noAccessPage = '/login') => {
  return (type, params) => {
    if (type === AUTH_LOGIN) {
      let storageData
      let timeToLive
      const request = new Request(loginApiUrl, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then(({ ttl, scopes, ...data }) => {
          storageData = data
          timeToLive = ttl
          if (
            scopes.includes('BookingManager') ||
            scopes.includes('Administrator')
          ) {
            storage.save('lbtoken', storageData, timeToLive)
            storage.save('permissions', scopes, timeToLive)
          } else {
            const err = new Error('No sufficient permissions')
            err.statusCode = 403
            throw err
          }
        })
    }
    if (type === AUTH_LOGOUT) {
      storage.remove('lbtoken')
      storage.remove('permissions')
      return Promise.resolve()
    }
    if (type === AUTH_ERROR) {
      const { status } = params
      if (status === 401 || status === 403) {
        storage.remove('lbtoken')
        storage.remove('permissions')
        return Promise.reject()
      }
      return Promise.resolve()
    }
    if (type === AUTH_CHECK) {
      const token = storage.load('lbtoken')
      const permissions = storage.load('permissions')
      if (
        token &&
        token.id &&
        permissions &&
        (permissions.includes('Administrator') ||
          permissions.includes('BookingManager'))
      ) {
        return Promise.resolve()
      } else {
        storage.remove('lbtoken')
        storage.remove('permissions')
        return Promise.reject({ redirectTo: noAccessPage })
      }
    }
    if (type === AUTH_GET_PERMISSIONS) {
      const role = localStorage.getItem('permissions')
      return role ? Promise.resolve(role) : Promise.reject()
    }
    return Promise.reject('Unknown method')
  }
}

export const authProvider = createAuthProvider(
  `${config.apiUrl}/participant/login`
)
