import * as React from 'react'
import { Create, SimpleForm, NumberInput, DateTimeInput } from 'react-admin'
import { parse } from 'query-string'

export const CommercialLocationBookingCreate = (props) => {
  const { locationId: locationId_string } = parse(props.location.search)
  const commercial_location_id = locationId_string
    ? parseInt(locationId_string, 10)
    : ''
  const redirect = commercial_location_id
    ? `/commercial_location/${commercial_location_id}/show/blocked_timeslots`
    : false

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ commercial_location_id }}
        redirect={redirect}>
        <code>{commercial_location_id}</code>
        <NumberInput
          label="Locatie ID"
          source="commercial_location_id"
          disabled={!!commercial_location_id}
        />
        <DateTimeInput label="Startdatum en tijd" source="startDateTime" />
        <DateTimeInput label="Einddatum en tijd" source="endDateTime" />
      </SimpleForm>
    </Create>
  )
}
