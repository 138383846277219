import * as React from 'react'

export const CountField = ({ field, value, data }) => {
  const array = data && Object.values(data)
  let amount = 0
  if (value) {
    amount =
      array &&
      array.reduce((num, date) => {
        return date[field] === value ? num + 1 : num
      }, 0)
  } else {
    amount = array ? array.length : 0
  }
  return <span>{amount}</span>
}

export const CurrencyField = ({ record = {}, source }) => {
  return (
    <span>
      {isNaN(parseFloat(record[source])) || record[source] === '0.00'
        ? '-'
        : parseFloat(record[source]).toLocaleString(undefined, {
            style: 'currency',
            currency: 'EUR',
          })}
    </span>
  )
}
