import * as React from 'react'
import Box from '@material-ui/core/Box'

export const BookingCustomFields = ({ record }) => {
  return Object.keys(record.booking_params).map((key, index) => (
    <div key={index}>
      <Box
        mb={1}
        fontSize={12}
        letterSpacing={0.15}
        fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
        color={'rgba(0,0,0,0.54)'}
        fontWeight={400}
        className=""
        data-shrink="true">
        <span>{key}</span>
      </Box>
      <Box mb={1.5}>
        <span className="MuiTypography-root MuiTypography-body2 t-2">
          {record.booking_params[key] === true
            ? 'Ja'
            : record.booking_params[key] === false
            ? 'Nee'
            : key === 'duration'
            ? `${record.booking_params[key]} minuten`
            : record.booking_params[key]}
        </span>
      </Box>
    </div>
  ))
}
