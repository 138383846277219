import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  BooleanField,
  TextField,
  ReferenceManyField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  EditButton,
  TopToolbar,
} from 'react-admin'
import { ParticipantAside } from './ParticipantAside'

const ParticipantShowActions = ({ isAdmin, basePath, data }) => {
  return (
    <TopToolbar>
      {isAdmin && <EditButton basePath={basePath} record={data} />}
    </TopToolbar>
  )
}

export const ParticipantShow = ({ permissions, ...props }) => {
  const permissionsJson = permissions && JSON.parse(permissions)
  const isAdmin = permissionsJson?.value?.includes('Administrator')
  return (
    <Show
      {...props}
      actions={<ParticipantShowActions isAdmin={isAdmin} />}
      aside={isAdmin ? <ParticipantAside /> : null}>
      {isAdmin ? (
        <SimpleShowLayout>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <TextField source="phoneNumber" />
          <TextField source="yearOfBirth" />
          <TextField source="gender" />
          <TextField source="city" />
          <TextField source="postalCode" />
          <TextField source="avatarUrl" />
          <BooleanField source="newsletterGeneral" />
          <TextField source="rangeChoice" />
          <ReferenceManyField
            label="Gevolgde sporten"
            reference="participant_activity"
            target="participantID"
            link={false}
            sortable={false}>
            <SingleFieldList>
              <ReferenceField
                source="activityID"
                reference="activity"
                link={false}>
                <ChipField source="name" />
              </ReferenceField>
            </SingleFieldList>
          </ReferenceManyField>
          <BooleanField source="commentsUpdate" />
          <BooleanField source="newsletterActivities" />
          <TextField source="bankAccountNumber" />
          <TextField source="bankAccountName" />
        </SimpleShowLayout>
      ) : (
        <SimpleShowLayout>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <TextField source="phoneNumber" />
        </SimpleShowLayout>
      )}
    </Show>
  )
}
