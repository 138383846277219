import React from 'react'
import {
  Show,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  Tab,
  TabbedShowLayout,
  useShowController,
  EditButton,
  TopToolbar,
  useNotify,
  fetchStart,
  fetchEnd,
  useDataProvider,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import { DateShortField, TimeRangeField } from './../components/Date'
import { CountField } from './../components/Number'
import { FullNameField } from './../components/Text'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const EventShowActions = ({ basePath, data }) => {
  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({
    groupInvites: false,
    generalInvites: false,
  })
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setState({ generalInvites: false, groupInvites: false })
    setOpen(false)
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const sendInvites = () => {
    const { groupInvites, generalInvites } = state
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`event/${data.id}/sendInvites`, {
        data: {
          sendGroupInvites: groupInvites,
          sendGeneralInvites: generalInvites,
        },
      })
      .then((resp) => {
        notify(`Gelukt! Uitnodiging(en) zijn opnieuw verstuurd`)
      })
      .catch(() => {
        notify('Er ging iets fout...', 'warning')
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
        handleClose()
      })
  }

  const classes = useStyles()

  const { groupInvites, generalInvites } = state
  return (
    <TopToolbar>
      <EditButton
        className={classes.button}
        basePath={basePath}
        record={data}
        variant="outlined"
      />
      <Button
        color="primary"
        onClick={handleClickOpen}
        size="small"
        variant="outlined"
        className={classes.button}
        endIcon={<SendIcon />}>
        Stuur uitnodigingen opnieuw
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Uitnodigingen nog een keer sturen?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Weet je zeker dat je uitnodigingen voor de data in de toekomst nog
            een keer wil sturen? Zo ja, selecteer hieronder dan of je de groep
            en/of de gebruikers in de buurt wilt uitnodigen. Er worden maximaal
            5 data getoond in de uitnodigingsmail
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={groupInvites}
                  onChange={handleChange}
                  name="groupInvites"
                  disabled={data && !data.groupID}
                />
              }
              label="Stuur uitnodigingen naar de groep"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={generalInvites}
                  onChange={handleChange}
                  name="generalInvites"
                />
              }
              label="Stuur uitnodigingen naar gebruikers in de buurt"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Nee, toch maar niet
          </Button>
          <Button
            onClick={sendInvites}
            color="primary"
            autoFocus
            disabled={(!groupInvites && !generalInvites) || loading}>
            Ja, ik weet het zeker!
          </Button>
        </DialogActions>
      </Dialog>
    </TopToolbar>
  )
}

export const EventShow = (props) => {
  const { record } = useShowController(props)
  if (record && record.securityHash) {
    console.log(record.securityHash)
  }
  return (
    <Show actions={<EventShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField label="Naam" source="name" />
          <TextField label="Beschrijving" source="description" />

          <TextField label="Prijs" source="cost" />
          <TextField label="Fee" source="fee" />
          <BooleanField label="Alleen strippenkaart" source="creditcardOnly" />
          <DateField label="Datum" source="startDate" />
          <TextField label="Starttijd" source="startTime" />
          <TextField label="Eindtijd" source="endTime" />
          <TextField label="Terugkerende activiteit" source="recurring" />
          <NumberField
            label="Maximaal aantal deelnemers"
            source="maximumParticipants"
          />
          <TextField label="Geslacht" source="gender" />
          <BooleanField label="Prive" source="private" />
          <TextField source="image" />
          <NumberField
            label="Timezone offset"
            source="timezoneOffset"
            value="2"
          />
          <ReferenceField
            label="Locatie"
            source="locationID"
            reference="location">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Organisator"
            source="ownerId"
            reference="participant"
            link="show">
            <FullNameField firstName="firstName" lastName="lastName" />
          </ReferenceField>
          <ReferenceField
            label="Activiteit"
            source="activityID"
            reference="activity">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Type"
            source="event_typeID"
            reference="event_type">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Groep" source="groupID" reference="group">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="imageUrl" />
          <TextField source="eventImage" />
        </Tab>
        <Tab label="Data" path="dates">
          <ReferenceManyField
            reference="date"
            target="eventID"
            addLabel={false}>
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" sortBy="id" />
              <DateShortField source="startDateTime" label="Datum" />
              <TimeRangeField
                start="startDateTime"
                end="endDateTime"
                label="Tijd"
              />
              <ReferenceField
                label="Prijs"
                source="eventID"
                reference="event"
                link={false}>
                <NumberField
                  source="cost"
                  options={{ style: 'currency', currency: 'EUR' }}
                />
              </ReferenceField>
              <ReferenceManyField
                label="Ja"
                target="dateID"
                reference="participant_date"
                sortable={false}>
                <CountField field="attendance" value="yes" />
              </ReferenceManyField>
              <ReferenceManyField
                label="?"
                target="dateID"
                reference="participant_date"
                sortable={false}>
                <CountField field="attendance" value="maybe" />
              </ReferenceManyField>
              <ReferenceManyField
                label="Nee"
                target="dateID"
                reference="participant_date"
                sortable={false}>
                <CountField field="attendance" value="no" />
              </ReferenceManyField>
              <ReferenceManyField
                label="Betaald"
                target="dateID"
                reference="participant_date"
                sortable={false}>
                <CountField field="paymentStatus" value="paid" />
              </ReferenceManyField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
