import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ReferenceField,
  SelectField,
  FunctionField,
} from 'react-admin'
import { FullNameField } from '../components/Text'

export const CreditcardList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <DateField source="created" label="Aangemaakt" />
      <ReferenceField
        source="owner_id"
        reference="participant"
        label="Gebruiker"
        link="show">
        <FullNameField firstName="firstName" lastName="lastName" />
      </ReferenceField>
      <TextField source="name" label="Naam" />
      <SelectField
        source="type"
        label="Geldig bij"
        choices={[
          { id: 'single_events', name: 'Los' },
          { id: 'group_events', name: 'Groep' },
          { id: 'owner_events', name: 'Organisator' },
          { id: 'page_events', name: 'Pagina' },
          { id: 'locations', name: 'Locatie' },
        ]}
      />
      <TextField source="cost" label="Prijs" />
      <FunctionField label="Fee" render={(record) => `${record.fee.value}%`} />
      <BooleanField source="active" label="Actief" />
    </Datagrid>
  </List>
)
