import * as React from 'react'
import {
  Show,
  DateField,
  TextField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  DeleteButton,
} from 'react-admin'
import { DatePaymentInfo } from './DatePaymentInfo'
import AddParticipantButton from './AddParticipantButton'
import { FullNameField } from '../components/Text'

export const DateShow = (props) => {
  return (
    <Show {...props} aside={<DatePaymentInfo />}>
      <TabbedShowLayout>
        <Tab label="Details">
          <ReferenceField
            source="eventID"
            reference="event"
            label="Event"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="eventID"
            reference="event"
            label="Organisator"
            link="show">
            <ReferenceField source="ownerId" reference="participant">
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          <DateField label="Datum" source="startDateTime" />
          <ReferenceField
            source="eventID"
            reference="event"
            label="Prijs"
            link={false}>
            <TextField source="cost" />
          </ReferenceField>
        </Tab>
        <Tab label="Deelnemers" path="participants">
          <ReferenceManyField
            reference="participant_date"
            target="dateID"
            perPage="0"
            addLabel={false}>
            <Datagrid>
              <ReferenceField
                source="participantID"
                reference="participant"
                label="Naam"
                link="show">
                <FullNameField firstName="firstName" lastName="lastName" />
              </ReferenceField>
              <TextField source="attendance" label="Aangemeld" />
              <TextField
                source="payment_data.payment_status"
                label="Betaalstatus"
              />
              <DeleteButton />
            </Datagrid>
          </ReferenceManyField>
          <AddParticipantButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
