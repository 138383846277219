import moment from 'moment'

export const dateFilterChange = (value, setFilters) => {
  switch (value) {
    case 'today':
      setFilters({
        key: value,
        and: [
          { startDateTime: { gte: moment().startOf('day').format() } },
          { startDateTime: { lte: moment().endOf('day').format() } },
        ],
      })
      break
    case 'nextSevenDays':
      setFilters({
        key: value,
        and: [
          { startDateTime: { lte: moment().add(7, 'd').format() } },
          { startDateTime: { gte: moment().startOf('day').format() } },
        ],
      })
      break
    case 'prevSevenDays':
      setFilters({
        key: value,
        and: [
          { startDateTime: { lte: moment().startOf('day').format() } },
          { startDateTime: { gte: moment().subtract(7, 'd').format() } },
        ],
      })
      break
    case 'thisMonth':
      setFilters({
        key: value,
        and: [
          { startDateTime: { lte: moment().endOf('month').format() } },
          {
            startDateTime: { gte: moment().startOf('month').format() },
          },
        ],
      })
      break
    case 'prevMonth':
      setFilters({
        key: value,
        and: [
          {
            startDateTime: {
              lte: moment().subtract(1, 'month').endOf('month').format(),
            },
          },
          {
            startDateTime: {
              gte: moment().subtract(1, 'month').startOf('month').format(),
            },
          },
        ],
      })
      break
    case 'yearToDate':
      setFilters({
        key: value,
        and: [
          {
            startDateTime: {
              lte: moment().format(),
            },
          },
          {
            startDateTime: {
              gte: moment().startOf('year').format(),
            },
          },
        ],
      })
      break
    case 'lastYear':
      setFilters({
        key: value,
        and: [
          {
            startDateTime: {
              lte: moment().subtract(1, 'year').endOf('year').format(),
            },
          },
          {
            startDateTime: {
              gte: moment().subtract(1, 'year').startOf('year').format(),
            },
          },
        ],
      })
      break
    default:
      setFilters({}, [])
  }
}
