import * as React from 'react'
import {
  List,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  useNotify,
  useDataProvider,
  fetchStart,
  fetchEnd,
  FunctionField,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import { CurrencyField } from '../components/Number'

const RefundButton = ({ record }) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const doRefund = () => {
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`payment/${record.id}/refund`, {
        data: { id: record.id, noRefundCosts: true },
      })
      .then(() => {
        notify(`Gelukt! Het bedrag is teruggestort`)
      })
      .catch((err) => {
        if (err.body?.error?.code === 'INSUFFICIENT_FUNDS') {
          notify(
            'Er staat niet genoeg saldo op de rekening van de merchant',
            'warning'
          )
        } else {
          notify('Er ging iets fout...', 'warning')
        }
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={doRefund}
      label="Refund"
      title="Refund"
      disabled={loading}>
      Refund
    </Button>
  )
}

const RefundDepositButton = ({ record }) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const changeRefundStatus = () => {
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`payment/refund_deposit/${record.id}`, {
        // data: { status: 'manually_refunded' },
      })
      .then(() => {
        notify(`Gelukt! Refund is aangepast`)
      })
      .catch(() => {
        notify('Er ging iets fout...', 'warning')
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={changeRefundStatus}
      label="Handmatige refund"
      title="Handmatige refund"
      disabled={loading}>
      Refund Deposit
    </Button>
  )
}

export const PaymentList = ({ permissions, ...props }) => {
  const permissionsJson = permissions && JSON.parse(permissions)
  const isAdmin = permissionsJson?.value?.includes('Administrator')
  return (
    <List {...props} sort={{ field: 'created', order: 'DESC' }}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="ID" />
        <DateField source="created" showTime label="Aangemaakt" />
        <ReferenceField
          source="participantID"
          reference="participant"
          label="Gebruiker"
          link="show">
          <FunctionField
            render={(record) =>
              `${record.firstName} ${record.lastName.charAt(0)}.`
            }
          />
        </ReferenceField>
        <CurrencyField source="amount" label="Bedrag" />
        <TextField source="type" label="Type" />
        <TextField source="status" />
        <FunctionField
          label="OPP transactie"
          render={(record) => (
            <a
              href={`https://partner.onlinepaymentplatform.com/transactions/${record.transaction_id}`}>
              {record.transaction_id}
            </a>
          )}
        />
        {isAdmin && (
          <FunctionField
            label="Refund"
            render={(record) =>
              record.status === 'completed' ? (
                <RefundButton record={record} />
              ) : record.status === 'waiting_for_manual_refund' ? (
                <RefundDepositButton record={record} />
              ) : null
            }
          />
        )}
      </Datagrid>
    </List>
  )
}
