import * as React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  ArrayField,
  BooleanField,
  Datagrid,
  TextField,
  ReferenceManyField,
  DeleteButton,
  FunctionField,
} from 'react-admin'
import Timeslots from '../components/Timeslots'
import { DateTimeWithDayField } from './../components/Date'
import AddBookingButton from './AddBookingButton'
import { makeStyles } from '@material-ui/core/styles'

const apiUrlWithoutApi = process.env.REACT_APP_API_URL.replace('/api', '')

const useStyles = makeStyles({
  image: {
    maxWidth: 200,
  },
})

export const CommercialLocationShow = (props) => {
  const classes = useStyles()
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Locatie details">
          <FunctionField
            label="Afbeelding"
            render={(record) => (
              <>
                <img
                  className={classes.image}
                  src={`${apiUrlWithoutApi}${record.imageUrl}`}
                  title={record.name}
                  alt={record.name}
                />
              </>
            )}
          />
          <TextField source="name" />
          <TextField source="address.city" label="Stad" />
          <TextField source="description" label="Beschrijving" />
          <FunctionField
            label="Beschikbare tijden"
            render={(record) =>
              record.timeslots.length &&
              record.availability_type === 'opening_hours' && (
                <Timeslots source="timeslots" />
              )
            }
          />
          <ArrayField source="pricing.data" label="Prijs tabel">
            <Datagrid>
              <NumberField source="duration" label="Minuten" />
              <BooleanField source="member" label="Lid?" />
              <NumberField
                options={{ style: 'currency', currency: 'EUR' }}
                source="price"
                label="Prijs"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Niet beschikbare tijden" path="blocked_timeslots">
          <ReferenceManyField
            reference={`commercial_location_booking`}
            target="commercial_location_id"
            addLabel={false}
            perPage={0}
            sort={{ field: 'startDateTime', order: 'ASC' }}>
            <Datagrid>
              <DateTimeWithDayField source="startDateTime" label="Start" />
              <DateTimeWithDayField source="endDateTime" label="Eind" />
              <DeleteButton
                redirect={`/commercial_location/${props.id}/show/blocked_timeslots`}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddBookingButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
