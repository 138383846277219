import { CommercialLocationList } from './CommercialLocationList'
import { CommercialLocationShow } from './CommercialLocationShow'
import { CommercialLocationCreate } from './CommercialLocationCreate'
import { CommercialLocationEdit } from './CommercialLocationEdit'
import RoomIcon from '@material-ui/icons/Room'

export const commercial_location = {
  create: CommercialLocationCreate,
  edit: CommercialLocationEdit,
  list: CommercialLocationList,
  icon: RoomIcon,
  show: CommercialLocationShow,
}
