import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
  ReferenceArrayInput,
  NumberInput,
} from 'react-admin'

const priceParams = [
  { key: 'court', title: 'Deel van de zaal', type: 'list' },
  {
    key: 'customer_type',
    title: 'Type huurder',
    type: 'list',
  },
  { key: 'duration', title: 'Duur', type: 'list' },
  {
    key: 'time_frame',
    title: 'Tijdsblok',
    type: 'calculated',
  },
]
const courts = [
  { id: 'hele zaal', name: 'hele zaal' },
  { id: 'hele sporthal', name: 'hele sporthal' },
]

export const CommercialLocationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Actief" source="published" />
      <TextInput label="Naam" source="name" required />
      <TextInput label="Beschrijving" multiline source="description" required />
      <TextInput
        source="image.container"
        label="Container"
        disabled
        defaultValue={'inviplay-219209.appspot.com'}
      />
      <TextInput source="image.file" label="File" />
      <TextInput label="Straat" source="address.street" required />
      <TextInput label="Huisnummer" source="address.houseNumber" required />
      <TextInput label="Postcode" source="address.postalCode" required />
      <TextInput label="Stad" source="address.city" required />
      <TextInput label="Slug" source="slug" required />
      <ReferenceArrayInput
        reference="activity_type"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={200}
        source="activities">
        <SelectArrayInput label="Sporten" />
      </ReferenceArrayInput>
      <SelectInput
        label="Baan titel"
        source="settings.court_title"
        choices={[
          { id: 'Deel van de zaal', name: 'Deel van de zaal' },
          { id: 'Baan', name: 'Baan' },
        ]}
      />
      <SelectArrayInput
        label="Beschikbare banen/zalen"
        onCreate={() => {
          const newCourtName = prompt('Nieuwe baan/zaal')
          const newCourt = {
            id: newCourtName.toLowerCase(),
            name: newCourtName,
          }
          courts.push(newCourt)
          return newCourt
        }}
        source="settings.available_courts"
        choices={courts}
      />
      <ArrayInput source="timeslots" label="Timeslots" defaultValue={[]}>
        <SimpleFormIterator>
          <SelectInput
            label="Kies dag"
            source="day"
            choices={[
              { id: 1, name: 'Maandag' },
              { id: 2, name: 'Dinsdag' },
              { id: 3, name: 'Woensdag' },
              { id: 4, name: 'Donderdag' },
              { id: 5, name: 'Vrijdag' },
              { id: 6, name: 'Zaterdag' },
              { id: 7, name: 'Zondag' },
            ]}
          />
          <TextInput source="startTime" label="Starttijd" />
          <TextInput source="endTime" label="Eindtijd" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="extra_info" label="Extra info" defaultValue={[]}>
        <SimpleFormIterator>
          <TextInput source="title" label="Titel" />
          <TextInput multiline source="content" label="Content" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput
        source="pricing.params"
        label="Prijs parameters"
        defaultValue={[]}>
        <SimpleFormIterator>
          <SelectInput
            source="title"
            label="Titel"
            choices={priceParams.map((param) => ({
              id: param.title,
              name: param.title,
            }))}
            defaultValue={priceParams[2].title}
          />
          <SelectInput
            label="Key"
            source="key"
            choices={priceParams.map((param) => ({
              id: param.key,
              name: param.key,
            }))}
            defaultValue={priceParams[2].key}
          />
          <SelectInput
            label="Type"
            source="type"
            choices={[
              { id: 'list', name: 'List' },
              { id: 'calculated', name: 'Calculated' },
            ]}
            defaultValue={priceParams[2].type}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="pricing.data" label="Prijs opties">
        <SimpleFormIterator>
          <NumberInput source="price" label="Prijs" />
          <NumberInput source="duration" label="Duur" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        label="Tekst in bevestigingse-mail"
        multiline
        source="confirmation_email_text"
      />
    </SimpleForm>
  </Create>
)
