import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  useMutation,
  fetchStart,
  fetchEnd,
  EditButton,
  ReferenceField,
  BooleanField,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import { FullNameField } from '../components/Text'
import { BookingCustomFields } from './BookingCustomFields'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import CloseIcon from '@material-ui/icons/Close'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
  },
  alert: {
    marginBottom: '1em',
  },
}))

const StatusField = ({ record }) => {
  const classes = useStyles()
  return (
    record.cancelled && (
      <Alert severity="warning" className={classes.alert}>
        Deze boeking is geannuleerd
      </Alert>
    )
  )
}

const BookingShowActions = ({ permissions, basePath, data }) => {
  const permissionsJson = permissions && JSON.parse(permissions)
  const isAdmin = permissionsJson?.value?.includes('Administrator')

  const [state, setState] = React.useState({
    court: '',
  })
  const [open, setOpen] = React.useState(false)
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = React.useState(
    false
  )
  const handleCloseCancelDialog = () => {
    setConfirmCancelDialogOpen(false)
  }
  const [loading, setLoading] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setState({ court: '' })
    setOpen(false)
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }
  const classes = useStyles()
  const dispatch = useDispatch()
  const notify = useNotify()
  const [mutate] = useMutation()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const hasCourtData = !!data?.commercial_location?.settings?.court_title

  const sendPaymentLink = () => {
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`booking/${data.id}/sendPaymentLink`, {
        data: { court: state.court },
      })
      .then((resp) => {
        notify(`Gelukt! Betaallink verstuurd`)
      })
      .catch(() => {
        notify('Er ging iets fout...', 'warning')
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
        handleClose()
      })
  }

  const useCreditcard = () => {
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`booking/${data.id}/useCreditcard`, {
        data: { court: state.court },
      })
      .then((resp) => {
        notify(`Gelukt!`)
      })
      .catch(() => {
        notify('Er ging iets fout...', 'warning')
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
        handleClose()
      })
  }

  const cancelBooking = async () => {
    dispatch(fetchStart())
    try {
      const res = await mutate(
        {
          type: 'update',
          resource: 'booking',
          payload: {
            id: data.id,
            data: {
              ...data,
              cancelled: true,
            },
          },
        },
        { returnPromise: true }
      )
      if (res.data) {
        notify(`Gelukt! Boeking geannuleerd`)
        redirect(`/booking`)
      }
    } catch (error) {
      notify('Er ging iets fout...', 'warning')
    } finally {
      dispatch(fetchEnd())
    }
  }

  return (
    <TopToolbar>
      {isAdmin && (
        <EditButton
          size="small"
          variant="outlined"
          className={classes.button}
          basePath={basePath}
          record={data}
          disabled={loading || data?.cancelled}
        />
      )}
      <Button
        color="primary"
        onClick={handleClickOpen}
        size="small"
        variant="outlined"
        className={classes.button}
        disabled={loading || data?.cancelled}
        endIcon={<SendIcon />}>
        Bevestig boeking
      </Button>
      <Button
        color="secondary"
        onClick={() => setConfirmCancelDialogOpen(true)}
        variant="outlined"
        size="small"
        className={classes.button}
        disabled={
          loading ||
          data?.cancelled ||
          data?.payment_data?.creditcard ||
          data?.payment_data?.creditcard_purchase_id
        }
        endIcon={<CloseIcon />}>
        Boeking annuleren
      </Button>
      <Dialog
        open={confirmCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Bevestig annuleren</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Paul, weet je zeker dat je deze boeking wilt annuleren? 😜
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} color="primary">
            Nee toch niet
          </Button>
          <Button
            onClick={cancelBooking}
            color="secondary"
            autoFocus
            disabled={loading}>
            Ja, annuleer!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Bevestig boeking</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data?.payment_data?.creditcard && (
              <>
                <p>
                  <strong>
                    Deze gebruiker heeft een strippenkaart voor deze locatie.
                  </strong>
                  <br />
                  Als je deze boeking bevestigd wordt er automatisch een strip
                  afgeboekt.
                </p>
              </>
            )}
          </DialogContentText>
          {hasCourtData && (
            <>
              <DialogContentText id="alert-dialog-description">
                Heb je gecheckt of de baan / zaal beschikbaar is en heb je deze
                afgehangen / gereserveerd in het goede systeem? Zo ja, welke
                baan / of (deel van de) zaal heb je afgehangen / gereserveerd?
              </DialogContentText>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  {data.commercial_location.settings.court_title || 'Baan'}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="court"
                  value={state.court}
                  displayEmpty
                  onChange={handleChange}>
                  {data?.commercial_location?.settings?.available_courts?.map(
                    (court) => (
                      <MenuItem value={`${court}`}>
                        {data.commercial_location.settings.court_title ===
                        'Baan'
                          ? `Baan ${court}`
                          : court}
                      </MenuItem>
                    )
                  )}
                </Select>
                <FormHelperText>
                  Selecteer de afgehangen baan / gereserveerde zaal
                </FormHelperText>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuleren
          </Button>

          {data?.payment_data?.creditcard ? (
            <Button
              onClick={useCreditcard}
              color="primary"
              autoFocus
              disabled={loading || (!state.court && hasCourtData)}>
              Gebruik strippenkaart
            </Button>
          ) : (
            <Button
              onClick={sendPaymentLink}
              color="primary"
              autoFocus
              disabled={loading || (!state.court && hasCourtData)}>
              Stuur betaallink
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </TopToolbar>
  )
}

export const BookingShow = ({ permissions, ...props }) => (
  <Show actions={<BookingShowActions permissions={permissions} />} {...props}>
    <SimpleShowLayout>
      <StatusField />
      <ReferenceField
        label="Locatie"
        source="commercial_location_id"
        reference="commercial_location"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField showTime source="created" label="Geboekt op" />
      <DateField
        showTime
        source="startDateTime"
        label="Begin (datum en tijd)"
      />
      <DateField showTime source="endDateTime" label="Einde (datum en tijd)" />
      <TextField source="cost" label="Prijs" />
      <TextField source="discount.value" label="Korting" />
      <BookingCustomFields />
      <BooleanField
        source="payment_data.payment_link_id"
        label="Betaallink verstuurd?"
        looseValue
      />
      <BooleanField source="payment_data.creditcard" label="Strippenkaart" />
      <BooleanField
        source="payment_data.creditcard_purchase_id"
        label="Strip afgeboekt?"
        looseValue
      />
      <TextField source="payment_data.court" label="Geboekte baan/zaal" />
      <ReferenceField
        label="Geboekt door"
        source="participant_id"
        reference="participant"
        link="show">
        <FullNameField firstName="firstName" lastName="lastName" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
