import * as React from 'react'
import { DateTime } from 'luxon'

export const DateShortField = ({ record = {}, source }) =>
  record[source] && (
    <span>
      {DateTime.fromISO(record[source]).toLocaleString(DateTime.DATE_MED)}
    </span>
  )

export const DateLongField = ({ record = {}, source }) =>
  record[source] && (
    <span>
      {DateTime.fromISO(record[source]).toLocaleString(DateTime.DATE_FULL)}
    </span>
  )

export const DateWithDayField = ({ record = {}, source }) =>
  record[source] && (
    <span>
      {DateTime.fromISO(record[source]).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      )}
    </span>
  )

export const DateTimeWithDayField = ({ record = {}, source }) =>
  record[source] && (
    <span>
      {DateTime.fromISO(record[source]).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      )}
    </span>
  )

export const DateTimeField = ({ record = {}, source }) => {
  const datetime = record[source]
  return (
    <span>
      {DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED)}
    </span>
  )
}

export const DateShortTimeField = ({ record = {}, source }) => {
  const datetime = record[source]
  return (
    <span>
      {DateTime.fromISO(datetime).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      )}
    </span>
  )
}

export const TimeField = ({ record = {}, source }) => {
  const datetime = record[source]
  return <span>{DateTime.fromISO(datetime).toFormat('HH:mm')}</span>
}

export const TimeRangeField = ({ record = {}, start, end }) => {
  const datetimestart = record[start]
  const datetimeend = record[end]
  return (
    <span>
      {DateTime.fromISO(datetimestart).toFormat('HH:mm')} -{' '}
      {DateTime.fromISO(datetimeend).toFormat('HH:mm')}
    </span>
  )
}
