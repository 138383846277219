import { ShowGuesser } from 'react-admin'
// import { MessageShow } from './MessageShow'
import { MessageList } from './MessageList'
import ForumIcon from '@material-ui/icons/Forum'

export const message = {
  list: MessageList,
  show: ShowGuesser,
  icon: ForumIcon,
}
