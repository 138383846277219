import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  // ArrayField,
  // SingleFieldList,
  // ChipField,
  ReferenceField,
} from 'react-admin'
import { FullNameField } from '../components/Text'

export const MerchantList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <ReferenceField
        source="participant_id"
        reference="participant"
        label="Gebruiker">
        <FullNameField firstName="firstName" lastName="lastName" />
      </ReferenceField>
      <TextField source="merchant_uid" label="Merchant UID" />
      <TextField source="status" />
      <NumberField source="compliance.level" />
    </Datagrid>
  </List>
)
