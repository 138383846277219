import React from 'react'
import {
  useGetList,
  TextField,
  ReferenceField,
  useDataProvider,
  useNotify,
  fetchStart,
  fetchEnd,
} from 'react-admin'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField as MuiInput,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export const ParticipantAside = ({ record, basePath }) => {
  const classes = useAsideStyles()
  return (
    <div className={classes.root}>
      {record && <EventList record={record} basePath={basePath} />}
      {record && <DiscountList record={record} basePath={basePath} />}
    </div>
  )
}

const EventList = (props) => {
  const { data: dates, ids: dateIds } = useGetList(
    'participant_date',
    { page: 1, perPage: 10 },
    { field: 'participantID', order: 'DESC' },
    { participantID: props.record && props.record.id }
  )
  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Laatste 10 deelnames
            </Typography>
            {dateIds.length > 0 &&
              Object.values(dates).map((item, idx) => (
                <Box key={idx}>
                  <ReferenceField
                    source="dateID"
                    reference="date"
                    basePath="/date"
                    link="show"
                    record={item}>
                    <ReferenceField
                      source="eventID"
                      reference="event"
                      link={false}>
                      <TextField source="name" />
                    </ReferenceField>
                  </ReferenceField>
                </Box>
              ))}
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

const DiscountList = (props) => {
  const dispatch = useDispatch()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const { data: rewards, ids: rewardIds } = useGetList(
    'reward',
    { page: 1, perPage: 0 },
    { field: 'participant_id', order: 'DESC' },
    { participant_id: props.record && props.record.id }
  )
  const [open, setOpen] = React.useState(false)
  const [amount, setAmount] = React.useState()
  const [loading, setLoading] = React.useState(false)

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const addReward = () => {
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`reward/`, {
        data: {
          status: 'rewarded',
          type: 'amount',
          value: amount * 100,
          participant_id: props.record.id,
        },
      })
      .then(() => {
        notify(`Gelukt! Korting toegevoegd`)
      })
      .catch(() => {
        notify('Er ging iets fout...', 'warning')
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
        setAmount('')
        setOpen(false)
      })
  }

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Kortingen
            </Typography>
            {rewardIds.length > 0 &&
              Object.values(rewards).map((item, idx) => {
                return (
                  <Box key={idx}>
                    <Box component="span" m="0 1em 0 0">
                      {DateTime.fromISO(item.created).toFormat('dd-MM-yyyy')}
                    </Box>
                    <Box component="span" m="0 1em 0 0">
                      {new Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(item.value / 100)}
                    </Box>
                    <Box component="span" m="0 1em 0 0">
                      {item.status}
                    </Box>
                  </Box>
                )
              })}
            <Box m="1em 0 0 0">
              <Button
                variant="contained"
                color="primary"
                onClick={openDialog}
                disabled={loading}>
                Geef korting
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Geef korting</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ken een korting toe aan deze deelnemer.
          </DialogContentText>
          <FormControl>
            <MuiInput
              value={amount}
              label="Euro"
              type="number"
              onChange={handleAmountChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Annuleren
          </Button>

          <Button onClick={addReward} color="primary" autoFocus>
            Geef korting
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
