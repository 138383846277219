import { ParticipantList } from './ParticipantList'
import { ParticipantShow } from './ParticipantShow'
import { ParticipantEdit } from './ParticipantEdit'
import UserIcon from '@material-ui/icons/Person'

export const participant = {
  show: ParticipantShow,
  list: ParticipantList,
  edit: ParticipantEdit,
  icon: UserIcon,
}
