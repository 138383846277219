import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  TopToolbar,
  useDataProvider,
  fetchStart,
  fetchEnd,
  ReferenceInput,
  SelectInput,
  Filter,
  FunctionField,
  TextInput,
} from 'react-admin'
import { DateWithDayField, TimeField } from '../components/Date'
import { CurrencyField } from '../components/Number'
import { FullNameField } from '../components/Text'
import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import ClockIcon from '@material-ui/icons/AccessTime'
import ReturnIcon from '@material-ui/icons/KeyboardReturn'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Chip from '@material-ui/core/Chip'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import { config } from '../config'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  open: {
    borderColor: '#ff9800',
    color: '#ff9800',
  },
  completed: {
    borderColor: '#4caf50',
    color: '#4caf50',
  },
  creditcard: {
    borderColor: '#2196f3',
    color: '#2196f3',
  },
  cancelled: {
    // gray color
    borderColor: '#9e9e9e',
    color: '#9e9e9e',
  },
  action: {
    borderColor: '#f50057',
    color: '#f50057',
  },
  refunded: {
    // purple color
    borderColor: '#9c27b0',
    color: '#9c27b0',
  },
}))

const BookingListActions = () => {
  const [state, setState] = React.useState({
    selectedLocation: '',
    selectedMonth: '',
  })
  const [locations, setLocations] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const dataProvider = useDataProvider()
  const lastSixMonths = [...Array(6).keys()].map((i) => ({
    index: DateTime.local().month - i,
    name: DateTime.local().minus({ month: i }).toFormat('LLLL yyyy'),
  }))
  const classes = useStyles()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setState({ selectedLocation: '', selectedMonth: '' })
    setOpen(false)
  }
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }
  const createCsvExport = async () => {
    setLoading(true)
    dispatch(fetchStart())
    fetch(`${config.apiUrl}/booking/csv_export`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...(state.selectedLocation
          ? { locationId: state.selectedLocation }
          : {}),
        month: state.selectedMonth,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        window.open(url, '_blank')
        URL.revokeObjectURL(url)
        dispatch(fetchEnd())
      })

    setLoading(false)
    handleClose()
  }

  React.useEffect(() => {
    dataProvider
      .getList(`commercial_location`, {
        pagination: { page: 1, perPage: 0 },
        sort: { field: 'name', order: 'ASC' },
      })
      .then((resp) => {
        if (resp?.data) setLocations(resp.data)
      })
  }, [dataProvider, setLocations])

  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size="small"
        variant="outlined"
        className={classes.button}
        endIcon={<CloudDownloadOutlined />}>
        CSV export
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Download CSV export</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Selecteer de locatie en de maand waarvoor je een export van de
            boekingen wilt ontvangen
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Locatie</InputLabel>
            <Select
              name="selectedLocation"
              value={state.selectedLocation}
              displayEmpty
              onChange={handleChange}>
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Maand</InputLabel>
            <Select
              name="selectedMonth"
              value={state.selectedMonth}
              displayEmpty
              onChange={handleChange}>
              {lastSixMonths.map((month) => (
                <MenuItem key={month.index} value={month.index}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuleren
          </Button>
          <Button
            onClick={createCsvExport}
            color="primary"
            autoFocus
            disabled={
              loading || (!state.selectedMonth && state.selectedMonth !== 0)
            }>
            Download CSV export
          </Button>
        </DialogActions>
      </Dialog>
    </TopToolbar>
  )
}

const StatusField = ({ record, ...rest }) => {
  const { payment_data } = record
  const dataProvider = useDataProvider()
  const [paymentStatus, setPaymentStatus] = React.useState(null)
  const classes = useStyles()

  if (payment_data?.payment_id) {
    dataProvider
      .getOne(`payment`, {
        id: payment_data.payment_id,
      })
      .then((resp) => {
        if (resp?.data?.status) {
          setPaymentStatus(resp.data.status)
        } else setPaymentStatus(null)
      })
  }

  const status = paymentStatus
    ? paymentStatus === 'completed' || paymentStatus === 'paid'
      ? 'completed'
      : paymentStatus === 'refunded'
      ? 'refunded'
      : paymentStatus === 'cancelled' ||
        paymentStatus === 'failed' ||
        paymentStatus === 'expired' ||
        paymentStatus === 'chargeback'
      ? 'failed'
      : 'open'
    : record.payment_data?.creditcard
    ? 'creditcard'
    : record.cancelled
    ? 'cancelled'
    : record.payment_data?.payment_link_send
    ? 'open'
    : 'action'
  const icon =
    status === 'cancelled' || status === 'failed' ? (
      <CloseIcon htmlColor="#ff0000" />
    ) : status === 'completed' || status === 'creditcard' ? (
      <CheckIcon />
    ) : status === 'open' ? (
      <ClockIcon />
    ) : status === 'action' ? (
      <ClockIcon />
    ) : status === 'refunded' ? (
      <ReturnIcon />
    ) : null
  const className =
    status === 'cancelled'
      ? classes.cancelled
      : status === 'completed'
      ? classes.completed
      : status === 'creditcard'
      ? classes.creditcard
      : status === 'failed'
      ? classes.cancelled
      : status === 'open'
      ? classes.open
      : status === 'refunded'
      ? classes.refunded
      : status === 'action'
      ? classes.action
      : null
  const chipLabel =
    status === 'cancelled'
      ? 'geannuleerd'
      : status === 'completed'
      ? 'betaald'
      : status === 'creditcard'
      ? 'strippenkaart'
      : status === 'failed'
      ? 'mislukt'
      : status === 'action'
      ? 'actie vereist'
      : status === 'refunded'
      ? 'teruggestort'
      : 'open'

  return (
    <FunctionField
      source="test"
      label="status"
      render={() => (
        <Chip
          variant="outlined"
          label={chipLabel}
          size="small"
          icon={icon}
          className={className}
          color="primary"
        />
      )}
    />
  )
}

const BookingFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="commercial_location_id"
      label="Locatie"
      reference="commercial_location"
      alwaysOn
      allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" label="Zoek op naam" alwaysOn resettable />
  </Filter>
)

export const BookingList = (props) => (
  <List
    actions={<BookingListActions />}
    {...props}
    filters={<BookingFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    title="Boekingen">
    <Datagrid rowClick="show">
      <TextField source="id" label="id" />
      <DateField showTime source="created" label="geboekt op" />
      <ReferenceField
        label="geboekt door"
        source="participant_id"
        reference="participant"
        link="show">
        <FullNameField firstName="firstName" lastName="lastName" />
      </ReferenceField>
      <ReferenceField
        label="locatie"
        source="commercial_location_id"
        reference="commercial_location"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateWithDayField source="startDateTime" label="geboekt voor" />
      <TimeField source="startDateTime" label="van" />
      <TimeField source="endDateTime" label="tot" />
      <CurrencyField source="cost" label="prijs" />
      <FunctionField
        label="korting"
        render={(record) =>
          record.discount?.type === 'percentage'
            ? `${record.discount?.value}%`
            : record.discount?.value
            ? new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
              }).format(record.discount?.value / 100)
            : '-'
        }
      />
      <FunctionField
        label="via"
        render={(record) =>
          record.data?.source === 'website' ? '👨‍💻' : '📲'
        }></FunctionField>
      <DateField
        source="payment_data.payment_link_send"
        label="betaallink verstuurd"
        showTime
      />
      <TextField source="payment_data.court" label="baan" />
      <StatusField />
    </Datagrid>
  </List>
)
