import * as React from 'react'
import { useCallback } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  FileInput,
  ImageField,
  useMutation,
  useRedirect,
  useRefresh,
} from 'react-admin'
import { config } from '../config'

const priceParams = [
  { key: 'court', title: 'Deel van de zaal', type: 'list' },
  {
    key: 'customer_type',
    title: 'Type huurder',
    type: 'list',
  },
  { key: 'duration', title: 'Duur', type: 'list' },
  {
    key: 'time_frame',
    title: 'Tijdsblok',
    type: 'calculated',
  },
]
const courts = [
  { id: 'hele zaal', name: 'hele zaal' },
  { id: 'hele sporthal', name: 'hele sporthal' },
]

export const CommercialLocationEdit = (props) => {
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [mutate] = useMutation()

  const save = useCallback(
    async (values) => {
      // check if file needs to be uploaded
      let uploadedImage = null
      if (values.file && values.file.rawFile instanceof File) {
        const formData = new FormData()
        formData.append('files', values.file.rawFile)
        const upload = await fetch(
          `${config.apiUrl}/attachments/${config.storageContainer}/upload`,
          {
            method: 'POST',
            headers: {},
            body: formData,
          }
        )
        const data = await upload.json()
        uploadedImage = data.result?.files?.files[0]
      }
      try {
        const res = await mutate(
          {
            type: 'update',
            resource: 'commercial_location',
            payload: {
              id: values.id,
              data: {
                ...values,
                image: {
                  container: uploadedImage?.container || values.image.container,
                  file: uploadedImage?.name || values.image.file,
                },
              },
            },
          },
          { returnPromise: true }
        )
        if (res.data) {
          redirect(`/commercial_location/${res.data.id}/show`)
          refresh()
        }
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors
        }
      }
    },
    [mutate, redirect, refresh]
  )

  return (
    <Edit {...props}>
      <SimpleForm save={save}>
        <h3>Algemeen</h3>
        <BooleanInput label="Actief" source="published" />
        <TextInput label="Naam" source="name" required />
        <TextInput label="Slug" source="slug" required />
        <TextInput
          label="Beschrijving"
          multiline
          source="description"
          required
        />
        <TextInput label="Locatie eigenaar ID" source="ownerId" required />
        <FileInput
          source="file"
          label="Locatie afbeelding"
          accept="image/png, image/jpg, image/jpeg">
          <ImageField source="src" title="Afbeelding" />
        </FileInput>
        <h3>Adres</h3>
        <TextInput label="Straat" source="address.street" required />
        <TextInput label="Huisnummer" source="address.houseNumber" required />
        <TextInput label="Postcode" source="address.postalCode" required />
        <TextInput label="Stad" source="address.city" required />
        <h3>Specificaties</h3>
        <ReferenceArrayInput
          reference="activity_type"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={200}
          source="activities">
          <SelectArrayInput label="Sporten" />
        </ReferenceArrayInput>
        <SelectInput
          label="Baan titel"
          source="settings.court_title"
          choices={[
            { id: 'Deel van de zaal', name: 'Deel van de zaal' },
            { id: 'Baan', name: 'Baan' },
          ]}
        />
        <SelectArrayInput
          label="Beschikbare banen/zalen"
          onCreate={() => {
            const newCourtName = prompt('Nieuwe baan/zaal')
            const newCourt = {
              id: newCourtName.toLowerCase(),
              name: newCourtName,
            }
            courts.push(newCourt)
            return newCourt
          }}
          source="settings.available_courts"
          choices={courts}
        />
        <h3>Openingstijden</h3>
        <ArrayInput source="timeslots" label="Timeslots">
          <SimpleFormIterator>
            <SelectInput
              label="Kies dag"
              source="day"
              choices={[
                { id: 1, name: 'Maandag' },
                { id: 2, name: 'Dinsdag' },
                { id: 3, name: 'Woensdag' },
                { id: 4, name: 'Donderdag' },
                { id: 5, name: 'Vrijdag' },
                { id: 6, name: 'Zaterdag' },
                { id: 7, name: 'Zondag' },
              ]}
            />
            <TextInput source="startTime" label="Starttijd" />
            <TextInput source="endTime" label="Eindtijd" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="extra_info" label="Extra info">
          <SimpleFormIterator>
            <TextInput source="title" label="Titel" />
            <TextInput multiline source="content" label="Content" />
          </SimpleFormIterator>
        </ArrayInput>

        <h3>Prijzen</h3>
        <ArrayInput source="pricing.params" label="Prijs opties">
          <SimpleFormIterator>
            <SelectInput
              source="title"
              label="Titel"
              choices={priceParams.map((param) => ({
                id: param.title,
                name: param.title,
              }))}
            />
            <SelectInput
              label="Key"
              source="key"
              choices={priceParams.map((param) => ({
                id: param.key,
                name: param.key,
              }))}
            />
            <SelectInput
              label="Type"
              source="type"
              choices={[
                { id: 'list', name: 'List' },
                { id: 'calculated', name: 'Calculated' },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="pricing.data" label="Prijzen">
          <SimpleFormIterator>
            <NumberInput source="price" label="Prijs" />
            <NumberInput source="duration" label="Duur" />
          </SimpleFormIterator>
        </ArrayInput>

        <h3>Fee</h3>
        <SelectInput
          label="Type"
          source="fee.type"
          choices={[
            { id: 'percentage', name: 'Percentage' },
            { id: 'fixed', name: 'Vast bedrag' },
          ]}
        />
        <NumberInput source="fee.value" label="Fee" />

        <h3>Instellingen</h3>
        <BooleanInput
          label="Automatisch bevestigen"
          source="settings.skip_confirmation"
        />
        <TextInput
          label="Tekst in bevestigingse-mail"
          multiline
          source="confirmation_email_text"
        />
        <TextInput
          label="Tekst over beschikbaarheid"
          multiline
          source="settings.availability_message"
        />
      </SimpleForm>
    </Edit>
  )
}
