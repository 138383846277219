import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  DeleteButton,
} from 'react-admin'

export const MessageList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <DateField source="sent_at" label="Verstuurd op" showTime />
      <ReferenceField
        label="Gebruiker"
        source="participant_id"
        reference="participant">
        <FunctionField
          render={(record) =>
            `${record.firstName} ${record.lastName.charAt(0)}.`
          }
        />
      </ReferenceField>
      <TextField source="content" label="Bericht" />
      <DeleteButton />
    </Datagrid>
  </List>
)
