import React from 'react'
import { Admin, Resource } from 'react-admin'
// import Dashboard from './dashboard/Dashboard'
import { booking } from './booking'
import { date } from './date'
import { event } from './event'
import { group } from './group'
// import { location } from './location'
import { message } from './message'
import { merchant } from './merchant'
import { payment } from './payment'
import { commercial_location } from './commercial_location'
import { commercial_location_booking } from './commercial_location_booking'
import { creditcard } from './creditcard'
import { creditcard_participants } from './creditcard_participants'
import { participant } from './participant'
import { participant_date } from './participant_date'
import { referral_campaign } from './referral_campaign'
import { createMuiTheme } from '@material-ui/core/styles'
import { dataProvider } from './dataProvider'
import { authProvider } from './authProvider'
import moment from 'moment'
import 'moment/locale/nl'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { messages } from './i18n/messages'
import './App.scss'
import CustomLayout from './CustomLayout'

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'nl', {
  allowMissing: true,
})

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
})

moment.locale('nl')

const App = () => {
  return (
    <>
      <Admin
        // dashboard={Dashboard}
        layout={CustomLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        theme={theme}>
        <Resource
          name="payment"
          options={{ label: 'Betalingen' }}
          {...payment}
        />
        <Resource
          options={{ label: 'Boekingen' }}
          name="booking"
          {...booking}
        />
        <Resource
          options={{ label: 'Chatberichten' }}
          name="message"
          {...message}
        />
        <Resource name="date" {...date} options={{ label: 'Datums' }} />
        <Resource name="event" {...event} options={{ label: 'Events' }} />
        <Resource
          options={{ label: 'Gebruikers' }}
          name="participant"
          {...participant}
        />
        <Resource name="activity" />
        <Resource name="activity_type" />
        <Resource name="participant_activity" />
        <Resource name="event_type" />
        <Resource name="group" options={{ label: 'Groepen' }} {...group} />
        <Resource
          name="commercial_location"
          options={{ label: 'Locaties' }}
          {...commercial_location}
        />
        <Resource
          name="merchant"
          options={{ label: 'Merchants' }}
          {...merchant}
        />
        <Resource
          name="commercial_location_booking"
          {...commercial_location_booking}
        />
        <Resource name="location" />
        <Resource name="creditcard_participants" {...creditcard_participants} />
        <Resource name="creditcard_events" />

        <Resource
          options={{ label: 'Strippenkaarten' }}
          name="creditcard"
          {...creditcard}
        />
        <Resource name="referral" />
        <Resource
          options={{ label: 'Referral Campaigns' }}
          name="referral_campaign"
          {...referral_campaign}
        />
        <Resource name="referral_event" />
        <Resource name="reward" />
        <Resource name="reward_type" />
        <Resource name="participant_date" {...participant_date} />
      </Admin>
    </>
  )
}

export default App
