import * as React from 'react'
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  BooleanField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  Button,
  Link,
  SelectField,
  ReferenceArrayField,
  useRecordContext,
  FunctionField,
} from 'react-admin'
import Box from '@material-ui/core/Box'
import Icon from '@material-ui/icons/Add'
import EventIcon from '@material-ui/icons/Event'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import { FullNameField } from '../components/Text'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
  },
}))

const AddParticipantButton = (record) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={classes.button}
      color="primary"
      component={Link}
      to={`/creditcard_participants/create?creditcardId=${record.id}`}
      label="Gebruiker toevoegen"
      title="Handmatige strippenkaart toevoegen">
      <Icon />
    </Button>
  )
}

const EventList = ({ data, ...rest }) => {
  const arr = Object.values(data)
  return (
    <List dense={true}>
      {arr.map((item) => (
        <ListItem>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
    </List>
  )
}

export const CreditcardShow = (props) => (
  <Show {...props}>
    <CreditcardShowLayout />
  </Show>
)

const CreditcardShowLayout = (props) => {
  const record = useRecordContext()
  return (
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="name" label="Naam" />
        <TextField source="description" label="Beschrijving" />
        <SelectField
          source="type"
          label="Geldig bij"
          choices={[
            { id: 'single_events', name: 'Losse activiteiten' },
            { id: 'group_events', name: 'Groepsactiviteiten' },
            { id: 'owner_events', name: 'Organisator activiteiten' },
            { id: 'page_events', name: 'Pagina activiteiten' },
            { id: 'locations', name: 'Locatie' },
          ]}
        />
        {record?.type === 'single_events' && (
          <ReferenceArrayField
            reference="event"
            source="details.events"
            label="Geselecteerde events">
            <EventList />
          </ReferenceArrayField>
        )}
        {record?.type === 'group_events' && (
          <ReferenceField
            reference="group"
            source="details.groupId"
            label="Groep">
            <TextField source="name" />
          </ReferenceField>
        )}
        {record?.type === 'page_events' && (
          <NumberField source="details.page_id" label="Pagina" />
        )}
        {record?.type === 'locations' && (
          <ReferenceField
            reference="commercial_location"
            source="details.locationId"
            label="Locatie">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="cost" label="Prijs" />
        <FunctionField
          label="Fee"
          render={(record) => `${record.fee.value}%`}
        />

        <DateField source="created" label="Aangemaakt" />
        <SelectField
          source="details.validity_period"
          label="Geldigheid"
          choices={[
            { name: 'Half jaar', id: '6,month' },
            { name: '1 jaar', id: '1,year' },
            { name: 'Onbeperkt', id: 'indefinitely' },
          ]}
        />
        <BooleanField source="active" label="Actief" />
        <NumberField
          source="details.times_to_use"
          label="Aantal keer te gebruiken"
        />
        <ReferenceField
          source="owner_id"
          reference="participant"
          label="Eigenaar"
          link="show">
          <FullNameField firstName="firstName" lastName="lastName" />
        </ReferenceField>
      </Tab>
      <Tab label="Gebruikers" path="participants">
        <ReferenceManyField
          reference="creditcard_participants"
          target="creditcard_id"
          perPage="0"
          addLabel={false}>
          <Datagrid
            rowClick={'show'}
            empty={
              <Box m={1.5} fontSize={14}>
                Er zijn nog geen gebruikers met deze strippenkaart
              </Box>
            }>
            <ReferenceField
              source="participant_id"
              reference="participant"
              label="Naam"
              link="show">
              <FullNameField firstName="firstName" lastName="lastName" />
            </ReferenceField>
            <DateField source="created" label="Gekocht op" />
            <TextField source="details.remaining" label="Strippen over" />
            <DateField source="details.valid_until" label="Geldig tot" />
            <TextField source="details.status" label="Status" />
          </Datagrid>
        </ReferenceManyField>
        <AddParticipantButton {...record} />
      </Tab>
    </TabbedShowLayout>
  )
}
