import { DateShow } from './DateShow'
import { DateList } from './DateList'
import { DateEdit } from './DateEdit'
import DateRangeIcon from '@material-ui/icons/DateRange'

export const date = {
  show: DateShow,
  edit: DateEdit,
  list: DateList,
  icon: DateRangeIcon,
}
