import * as React from 'react'
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  ReferenceManyField,
  DateField,
} from 'react-admin'
import { CountField } from '../components/Number'

const ParticipantFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export const ParticipantList = (props) => (
  <List
    filters={<ParticipantFilter />}
    title="Gebruikers"
    {...props}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <DateField showTime source="created" label="Aangemeld" />
      <TextField source="firstName" label="Voornaam" />
      <TextField source="lastName" label="Achternaam" />
      <TextField source="email" label="E-mail" />
      <TextField source="city" label="Stad" />
      <TextField source="postalCode" label="Postcode" />
      <BooleanField source="newsletterActivities" label="Invites" />
      <ReferenceManyField
        label="Gevolgde sporten"
        reference="participant_activity"
        target="participantID"
        link={false}
        sortable={false}>
        <CountField field="id" />
      </ReferenceManyField>
      <TextField source="source" label="Type" />
      <TextField source="status" label="Status" />
      <EditButton />
    </Datagrid>
  </List>
)
