import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import { JsonInput } from 'react-admin-json-view'

export const CreditcardEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" label="Naam" />
        <TextInput source="description" label="Beschrijving" />
        <SelectInput
          source="type"
          label="Geldig bij"
          choices={[
            { id: 'single_events', name: 'Losse activiteiten' },
            { id: 'group_events', name: 'Groepsactiviteiten' },
            { id: 'owner_events', name: 'Organisator activiteiten' },
            { id: 'page_events', name: 'Pagina activiteiten' },
            { id: 'locations', name: 'Locatie' },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.type === 'single_events' && (
              <JsonInput
                label="Losse events"
                source="details.events"
                reactJsonOptions={{
                  name: null,
                  collapsed: true,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
                {...rest}
              />
            )) ||
            (formData.type === 'group_events' && (
              <NumberInput label="Groep" source="details.groupId" {...rest} />
            )) ||
            (formData.type === 'page_events' && (
              <NumberInput label="Pagina" source="details.page_id" {...rest} />
            )) ||
            (formData.type === 'locations' && (
              <NumberInput
                label="Locatie"
                source="details.locationId"
                {...rest}
              />
            ))
          }
        </FormDataConsumer>
        <TextInput source="cost" label="Prijs" />
        <TextInput source="fee.value" label="Fee" />

        <BooleanInput source="active" label="Actief" />
        <NumberInput
          source="details.times_to_use"
          label="Aantal keer te gebruiken"
        />
        <SelectInput
          source="details.validity_period"
          label="Geldigheid"
          choices={[
            { name: 'Half jaar', id: '6,month' },
            { name: '1 jaar', id: '1,year' },
            { name: 'Onbeperkt', id: 'indefinitely' },
          ]}
        />
        <NumberInput source="owner_id" label="Eigenaar" />
      </SimpleForm>
    </Edit>
  )
}
