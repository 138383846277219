import * as React from 'react'
import { Edit, SimpleForm, DateTimeInput } from 'react-admin'

export const DateEdit = (props) => (
  <Edit title="Event edit" {...props}>
    <SimpleForm>
      <DateTimeInput label="Startijd" source="startDateTime" required />
      <DateTimeInput label="Eindtijd" source="endDateTime" required />
    </SimpleForm>
  </Edit>
)
