import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@material-ui/icons/GroupAdd'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'

const styles = {
  button: {
    marginTop: '1em',
  },
}

const AddParticipantButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/participant_date/create?dateId=${record.id}`}
    label="Voeg deelnemer toe"
    title="Voeg deelnemer toe">
    <Icon />
  </Button>
)

export default withStyles(styles)(AddParticipantButton)
