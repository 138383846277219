import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@material-ui/icons/CalendarTodayOutlined'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}

const AddBookingButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    color="default"
    component={Link}
    to={`/commercial_location_booking/create?locationId=${record.id}`}
    label="Niet beschikbare tijd toevoegen"
    title="Niet beschikbare tijd toevoegen">
    <Icon />
  </Button>
)

export default withStyles(styles)(AddBookingButton)
