import { BookingList } from './BookingList'
import { BookingShow } from './BookingShow'
import { BookingEdit } from './BookingEdit'
import SportsTennisIcon from '@material-ui/icons/SportsTennis'

export const booking = {
  list: BookingList,
  show: BookingShow,
  edit: BookingEdit,
  icon: SportsTennisIcon,
}
