import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'
import { DateTime } from 'luxon'

export const exporter = async (dates) => {
  const data = dates.map((date) => {
    const cost = date.cost ? parseFloat(date.cost) : 0
    const fee = date.fee ? parseFloat(date.fee) : 0
    return {
      'event ID': date.eventId,
      event: date.name,
      'datum ID': date.id,
      datum: DateTime.fromISO(date.startDateTime).toLocaleString(),
      tijd: `${DateTime.fromISO(date.startDateTime).toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })} - ${DateTime.fromISO(date.startDateTime).toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}`,
      betalingen: date.payments,
      deelnemers: date.participants,
      bedrag: cost.toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      }),
      fee: fee.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' }),
      totale_fee: (date.payments * fee).toLocaleString('nl-nl', {
        style: 'currency',
        currency: 'EUR',
      }),
      organisator: date.organizer,
      iban: date.bankAccountNumber,
      rekeninghouder: date.bankAccountName,
    }
  })
  jsonExport(data, (err, csv) => {
    downloadCSV(csv, `dates_export_${DateTime.local().toISO()}`)
  })
}
