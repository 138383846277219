import React from 'react'
import { Layout, Menu as DefaultMenu, usePermissions } from 'react-admin'
import { useSelector } from 'react-redux'
import { MenuItemLink } from 'react-admin'
import CalendarToday from '@material-ui/icons/CalendarToday'

const Menu = ({ onMenuClick }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen)

  return (
    <div>
      <br />
      <MenuItemLink
        key={'booking'}
        to={`/booking`}
        primaryText="Boekingen"
        leftIcon={<CalendarToday />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    </div>
  )
}

const CustomLayout = (props) => {
  const { loaded, permissions } = usePermissions()
  const roles = permissions && JSON.parse(permissions).value
  const onlyAdmin =
    roles &&
    roles.includes('BookingManager') &&
    !roles.includes('Administrator')
  return loaded ? (
    <Layout {...props} menu={onlyAdmin ? Menu : DefaultMenu} />
  ) : null
}

export default CustomLayout
