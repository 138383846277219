import { CreditcardShow } from './CreditcardShow'
import { CreditcardList } from './CreditcardList'
import { CreditcardEdit } from './CreditcardEdit'
import { CreditcardCreate } from './CreditcardCreate'
import CreditCardIcon from '@material-ui/icons/CreditCard'

export const creditcard = {
  create: CreditcardCreate,
  list: CreditcardList,
  show: CreditcardShow,
  edit: CreditcardEdit,
  icon: CreditCardIcon,
}
