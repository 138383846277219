import * as React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid,
  useDataProvider,
  useNotify,
  fetchStart,
  fetchEnd,
} from 'react-admin'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import ButtonUi from '@material-ui/core/Button'
import ReturnIcon from '@material-ui/icons/Replay'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { FullNameField } from '../components/Text'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'

const ReturnBooking = ({ record }) => {
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const handleClickOpen = () => setOpen(true)
  const handleClickClose = () => setOpen(false)
  const useStyles = makeStyles((theme) => ({
    button: {
      // margin: theme.spacing(1),
    },
  }))
  const styles = useStyles()
  const returnCredit = () => {
    dispatch(fetchStart())
    dataProvider
      .create(`creditcard/${record.creditcard_id}/return`, {
        data: {
          participantId: record.participant_id,
          bookingId: record.data.booking_id,
          dateId: record.data.date_id,
          purchaseId: record.data.purchase_id,
        },
      })
      .then((resp) => {
        notify(`Gelukt! Strip is teruggezet op de strippenkaart`)
      })
      .catch(() => {
        notify('Er ging iets fout...', 'warning')
      })
      .finally(() => {
        dispatch(fetchEnd())
        handleClickClose()
      })
  }
  return record.type === 'use' ? (
    <>
      <ButtonUi
        size="small"
        variant="outlined"
        className={styles.button}
        color="secondary"
        startIcon={<ReturnIcon />}
        disableElevation
        onClick={handleClickOpen}>
        Terugboeken
      </ButtonUi>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Strip terugboeken?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Weet je zeker dat je deze strip wilt terugboeken?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonUi
            variant="outlined"
            onClick={handleClickClose}
            color="primary">
            Annuleren
          </ButtonUi>
          <ButtonUi
            variant="contained"
            onClick={returnCredit}
            startIcon={<ReturnIcon />}
            color="secondary">
            Terugboeken
          </ButtonUi>
        </DialogActions>
      </Dialog>
    </>
  ) : record.type === 'returned' ? (
    <Box fontWeight="fontWeightLight" fontSize={12} alignContent="center">
      <div>
        Teruggeboekt op{' '}
        {DateTime.fromISO(record.data.returned_at).toLocaleString(
          DateTime.DATETIME_SHORT
        )}
      </div>
    </Box>
  ) : null
}

export const CreditcardParticipantsShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="purchase_id" label="Aankoop ID" />
        <TextField source="details.status" label="Status" />
        <TextField source="details.remaining" label="Strippen over" />
        <DateField source="created" label="Aangemaakt" />
        <DateField source="details.valid_until" label="Geldig tot" />
        <ReferenceField
          source="participant_id"
          reference="participant"
          label="Gebruiker"
          link="show">
          <FullNameField firstName="firstName" lastName="lastName" />
        </ReferenceField>
        <ReferenceField
          source="creditcard_id"
          reference="creditcard"
          link="show">
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="Gebruikte strippen" path="used">
        <ReferenceManyField
          source="purchase_id"
          reference="creditcard_events"
          target="data.purchase_id"
          perPage="0"
          addLabel={false}
          sortBy="datetime">
          <Datagrid
            empty={
              <Box m={1.5} fontSize={14}>
                Nog geen strippen gebruikt
              </Box>
            }>
            <DateField showTime source="datetime" label="Datum" />
            <ReferenceField
              source="data.booking_id"
              reference="booking"
              link="show"
              label="Booking">
              <TextField source="id" />
            </ReferenceField>
            <ReferenceField
              source="data.date_id"
              reference="date"
              link="show"
              label="Datum">
              <TextField source="id" />
            </ReferenceField>
            <ReturnBooking />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
