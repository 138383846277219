import * as React from 'react'
import { Create, SimpleForm, NumberInput, SelectInput } from 'react-admin'
import { parse } from 'query-string'

export const ParticipantDateCreate = (props) => {
  const { dateId: dateId_string } = parse(props.location.search)
  const dateID = dateId_string ? parseInt(dateId_string, 10) : ''
  const redirect = dateID ? `/date/${dateID}/show/participants` : false

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ dateID }} redirect={redirect}>
        <NumberInput label="Deelnemer ID" source="participantID" />
        <NumberInput label="Datum ID" source="dateID" disabled={!!dateID} />
        <SelectInput
          source="attendance"
          label="Aanwezigheid"
          choices={[
            { id: 'yes', name: 'Ja' },
            { id: 'maybe', name: 'Misschien' },
            { id: 'no', name: 'Nee' },
          ]}
        />
      </SimpleForm>
    </Create>
  )
}
