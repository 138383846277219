import { EventCreate } from './EventCreate'
import { EventEdit } from './EventEdit'
import { EventList } from './EventList'
import { EventShow } from './EventShow'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'

export const event = {
  create: EventCreate,
  edit: EventEdit,
  list: EventList,
  show: EventShow,
  icon: EmojiEventsIcon,
}
