import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

const PostTitle = ({ record }) => {
  return <span>Event {record ? `"${record.name}"` : ''}</span>
}

export const EventEdit = (props) => (
  <Edit title={<PostTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Naam" source="name" required />
      <TextInput multiline label="Beschrijving" source="description" required />
      <TextInput label="Prijs" source="cost" />
      <TextInput label="Fee" source="fee" disabled />
      <BooleanInput
        label="Alleen betalen met strippenkaart"
        source="creditcardOnly"
      />
      <DateInput label="Datum" source="startDate" required />
      <TextInput label="Starttijd" source="startTime" required />
      <TextInput label="Eindtijd" source="endTime" required />
      <SelectInput
        allowEmpty
        label="Terugkerende activiteit"
        source="recurring"
        choices={[
          { id: '7,day', name: 'Wekelijks' },
          { id: '14,day', name: 'Tweewekelijks' },
          { id: '1,month', name: 'Maandelijks' },
          { id: '6,month', name: 'Halfjaarlijks' },
          { id: '1,year', name: 'Jaarlijks' },
        ]}
      />
      <NumberInput
        label="Maximaal aantal deelnemers"
        source="maximumParticipants"
        required
      />
      <BooleanInput label="Prive" source="private" />
      <NumberInput label="Locatie" source="locationID" required />
      <NumberInput label="Organisator" source="ownerID" required />
      <NumberInput label="Activiteit" source="activity_type_id" required />
      <ReferenceInput
        label="Type"
        source="event_typeID"
        reference="event_type"
        required>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <NumberInput label="Groep" source="groupID" />
    </SimpleForm>
  </Edit>
)
