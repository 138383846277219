import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  DateField,
} from 'react-admin'

export const ReferralCampaignList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="event" />
      <BooleanField source="active" />
      <DateField source="period_start" />
      <DateField source="period_end" />
      <ReferenceField
        source="reward_type_id"
        reference="reward_type"
        label="Reward">
        <TextField source="title" />
      </ReferenceField>
    </Datagrid>
  </List>
)
