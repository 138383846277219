import dutchMessages from 'ra-language-dutch'
import englishMessages from 'ra-language-english'

export const messages = {
  nl: {
    ra: {
      ...dutchMessages.ra,
      filter: {
        period: 'Periode',
        today: 'Vandaag',
        nextSevenDays: 'Komende 7 dagen',
        prevSevenDays: 'Afgelopen 7 dagen',
        thisMonth: 'Deze maand',
        lastMonth: 'Afgelopen maand',
      },
    },
  },
  en: {
    ra: {
      ...englishMessages.ra,
      filter: {
        period: 'Periode',
        today: 'Vandaag',
        nextSevenDays: 'Next 7 days',
        prevSevenDays: 'Last 7 days',
        thisMonth: 'This month',
        lastMonth: 'Last month',
      },
    },
  },
}
