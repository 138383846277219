import React from 'react'
import Box from '@material-ui/core/Box'

export const FullNameField = ({ firstName, lastName, record }) => {
  return (
    <>
      {firstName && lastName && (
        <Box fontSize={14}>
          {record[firstName]} {record[lastName]}
        </Box>
      )}
    </>
  )
}

export const EventTypeField = ({ source, record }) => {
  const mappedValues = {
    Holidaymaker: 'Recreant',
    Commercial: 'Commercieel',
    Association: 'Vereniging',
  }
  return <span>{mappedValues[record[source]]}</span>
}
