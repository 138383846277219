import React, { useState } from 'react'
import {
  Datagrid,
  ExportButton,
  TextField,
  NumberField,
  TopToolbar,
  useListContext,
  Pagination,
  useQueryWithStore,
  Loading,
  Error,
  ListContextProvider,
} from 'react-admin'
import { DateShortField, TimeRangeField } from './../components/Date'
import { CurrencyField } from './../components/Number'
import { exporter } from './exporter'
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
  Box,
  Card,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/ClearOutlined'
import { dateFilterChange } from '../utils/dateFilters'
import keyBy from 'lodash/keyBy'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  },
  formControl: {
    flex: 1,
    maxWidth: '10rem',
    marginRight: '2rem',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterBar: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  button: {
    margin: theme.spacing(1),
  },
}))

const DateActions = () => {
  const classes = useStyles()
  const { setFilters, filterValues } = useListContext()
  const [dateFilter, setDateFilter] = useState('')
  const [paidFilter, setPaidFilter] = useState(false)

  React.useEffect(() => {
    if (filterValues.key) {
      setDateFilter(filterValues.key)
    }
    if (filterValues.cost) {
      setPaidFilter(true)
    }
  }, [filterValues])

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value)
    dateFilterChange(event.target.value, setFilters)
  }

  const paidFilterChange = async (event) => {
    setPaidFilter(event.target.checked)
    if (event.target.checked) {
      // setFilters({ and: [...filters.and, { cost: null }] })
      setFilters({
        ...filterValues,
        cost: {
          neq: '0.00',
        },
      })
    } else {
      setFilters({ ...filterValues, cost: null })
    }
  }

  const clearFilters = () => {
    setDateFilter('')
    setPaidFilter(false)
    setFilters({}, [])
  }

  return (
    <>
      <TopToolbar className={classes.toolbar}>
        <Box className={classes.filterBar}>
          <FormControl className={classes.formControl}>
            <InputLabel id="date-label">Periode</InputLabel>
            <Select
              labelId="date-label"
              id="demo-simple-select"
              value={dateFilter}
              onChange={handleDateFilterChange}>
              <MenuItem value={'today'}>Vandaag</MenuItem>
              <MenuItem value={'nextSevenDays'}>Komende 7 dagen</MenuItem>
              <MenuItem value={'prevSevenDays'}>Afgelopen 7 dagen</MenuItem>
              <MenuItem value={'thisMonth'}>Deze maand</MenuItem>
              <MenuItem value={'prevMonth'}>Afgelopen maand</MenuItem>
              <MenuItem value={'yearToDate'}>Tot nu toe dit jaar</MenuItem>
              <MenuItem value={'lastYear'}>Vorig jaar</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={paidFilter}
                onChange={paidFilterChange}
                name="paidFilter"
              />
            }
            label="Alleen betaalde events"
          />
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={clearFilters}
            startIcon={<ClearIcon />}>
            Reset filters
          </Button>
        </Box>
        <ExportButton
          exporter={exporter}
          resource="date/admin"
          maxResults={0}
        />
      </TopToolbar>
    </>
  )
}

export const DateList = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sort, setSort] = useState({ field: 'startDateTime', order: 'DESC' })
  const [filters, setFilters] = useState({})
  const { data, total, loading, error } = useQueryWithStore({
    type: 'getList',
    resource: 'date/admin',
    payload: {
      pagination: { page, perPage },
      sort,
      filter: filters,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error error={error} />
  if (!data) return null

  return (
    <ListContextProvider
      value={{
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        total,
        page,
        perPage,
        setPage,
        setFilters,
        filterValues: filters,
        resource: 'date/admin',
        currentSort: sort,
        setSort: (field, order) => setSort({ field, order }),
      }}>
      <DateActions />
      <Card>
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <TextField label="Activiteit" source="name" />
          <DateShortField
            source="startDateTime"
            label="Datum"
            sortBy="startDateTime"
          />
          <TimeRangeField
            start="startDateTime"
            end="endDateTime"
            label="Tijd"
          />
          <CurrencyField label="Prijs" source="cost" />
          <CurrencyField label="Fee" source="fee" />
          <NumberField source="participants" label="Deelnemers" />
          <NumberField source="payments" label="Betaald" />
        </Datagrid>
      </Card>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={total}
      />
    </ListContextProvider>
  )
}
