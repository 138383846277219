import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  NumberField,
  TopToolbar,
  useNotify,
  useDataProvider,
  fetchStart,
  fetchEnd,
} from 'react-admin'
import { useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'

const RefundButton = ({ record }) => {
  console.log(record)
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const doRefund = () => {
    setLoading(true)
    dispatch(fetchStart())
    dataProvider
      .create(`payment/${record.id}/refund`, {
        data: { id: record.id, noRefundCosts: true },
      })
      .then(() => {
        notify(`Gelukt! Het bedrag is teruggestort`)
      })
      .catch((err) => {
        if (err.body?.error?.code === 'INSUFFICIENT_FUNDS') {
          notify(
            'Er staat niet genoeg saldo op de rekening van de merchant',
            'warning'
          )
        } else {
          notify('Er ging iets fout...', 'warning')
        }
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchEnd())
      })
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={doRefund}
      label="Refund"
      title="Refund"
      disabled={loading}>
      Refund
    </Button>
  )
}

const PaymentShowActions = ({ permissions, basePath, data }) => {
  const permissionsJson = permissions && JSON.parse(permissions)
  const isAdmin = permissionsJson?.value?.includes('Administrator')
  return (
    <>
      <TopToolbar>{isAdmin && <RefundButton record={data} />}</TopToolbar>
    </>
  )
}

export const PaymentShow = ({ permissions, ...props }) => (
  <Show actions={<PaymentShowActions permissions={permissions} />} {...props}>
    <SimpleShowLayout>
      <DateField source="created" />
      <DateField source="updated" />
      <TextField source="amount" />
      <TextField source="status" />
      <TextField source="transaction_id" />
      <TextField source="type" />
      <NumberField source="participantID" label={'Gebruiker ID'} />
    </SimpleShowLayout>
  </Show>
)
