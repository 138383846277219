import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'
import { DateTime } from 'luxon'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },
}))

const Timeslots = (props) => {
  const classes = useStyles()
  const { source } = props
  const record = useRecordContext(props)
  const timeslots = record[source].reduce((slots, slot) => {
    const weekday = DateTime.fromFormat(slot.day.toString(), 'c').weekdayLong
    return {
      ...slots,
      [weekday]: [
        ...(slots[weekday]
          ? [
              ...slots[weekday],
              { startTime: slot.startTime, endTime: slot.endTime },
            ]
          : [{ startTime: slot.startTime, endTime: slot.endTime }]),
      ],
    }
  }, {})
  return (
    <>
      {Object.keys(timeslots).map((day) => (
        <Box my={0.5} display="flex">
          {day}
          {timeslots[day].map((s) => (
            <div className={classes.root}>
              <Chip
                size="small"
                outlined
                label={`${s.startTime} - ${s.endTime}`}
              />
            </div>
          ))}
        </Box>
      ))}
    </>
  )
}

Timeslots.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default Timeslots
