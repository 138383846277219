import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  BooleanField,
  Filter,
  TextInput,
  FunctionField,
  useRecordContext,
  BooleanInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const apiUrlWithoutApi = process.env.REACT_APP_API_URL.replace('/api', '')

const useStyles = makeStyles({
  image: {
    maxWidth: 80,
    borderRadius: 8,
  },
})

const LocationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Zoeken" source="q" alwaysOn resettable />
    <BooleanInput label="Actief" source="published" />
  </Filter>
)

const CustomImageField = (props) => {
  const record = useRecordContext(props)
  const classes = useStyles()

  return record && record.imageUrl ? (
    <FunctionField
      label="Afbeelding"
      render={(record) => (
        <>
          <img
            src={`${apiUrlWithoutApi}${record.imageUrl}`}
            title={record.name}
            className={classes.image}
            alt={record.name}
          />
        </>
      )}
    />
  ) : null
}

export const CommercialLocationList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'ASC' }}
      filter={{ show_unpublished: true }}
      filters={<LocationFilter />}>
      <Datagrid rowClick="show">
        <TextField source="id" label="id" />
        <CustomImageField />
        <TextField source="name" label="naam" />
        <TextField source="address.city" label="stad" />
        <ReferenceArrayField
          label="sporten"
          reference="activity"
          source="activities">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="fee"
          render={(record) =>
            record.fee.type === 'percentage'
              ? `${record.fee.value}%`
              : record.fee.type === 'value'
              ? `€${record.fee.value}`
              : ''
          }
        />
        <ReferenceField
          source="ownerId"
          reference="participant"
          label="eigenaar">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="published" label="actief" />
      </Datagrid>
    </List>
  )
}
