import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

export const EventCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Naam" source="name" required />
      <TextInput multiline label="Beschrijving" source="description" required />
      <TextInput label="Prijs" source="cost" />
      <TextInput label="Fee" source="fee" disabled defaultValue={'0.50'} />
      <BooleanInput
        label="Alleen betalen met strippenkaart"
        source="creditcardOnly"
      />
      <DateInput label="Datum" source="startDate" required />
      <TextInput label="Starttijd" source="startTime" required />
      <TextInput label="Eindtijd" source="endTime" required />
      <SelectInput
        allowEmpty
        label="Terugkerende activiteit"
        source="recurring"
        choices={[
          { id: '7,Date', name: 'Wekelijks' },
          { id: '14,Date', name: 'Tweewekelijks' },
          { id: '1,Month', name: 'Maandelijks' },
          { id: '6,Month', name: 'Halfjaarlijks' },
          { id: '1,Year', name: 'Jaarlijks' },
        ]}
      />
      <NumberInput
        label="Maximaal aantal deelnemers"
        source="maximumParticipants"
        required
      />
      <BooleanInput label="Prive" source="private" />
      <NumberInput label="Locatie" source="locationID" required />
      <NumberInput label="Organisator" source="ownerID" required />
      <NumberInput label="Activiteit type" source="activity_type_id" required />
      <ReferenceInput
        required
        label="Type"
        source="event_typeID"
        reference="event_type">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <NumberInput label="Groep" source="groupID" />
    </SimpleForm>
  </Create>
)
