// import { EventCreate } from './EventCreate'
// import { EventEdit } from './EventEdit'
import { GroupList } from './GroupList'
// import { EventShow } from './EventShow'
import { ShowGuesser } from 'react-admin'
import GroupIcon from '@material-ui/icons/Group'

export const group = {
  // create: EventCreate,
  // edit: EventEdit,
  list: GroupList,
  show: ShowGuesser,
  icon: GroupIcon,
}
