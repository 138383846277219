import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider } from 'react-admin'

const useStyles = makeStyles({
  container: { minWidth: '15em', marginLeft: '1em', padding: '1rem' },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
  label: {
    fontSize: '0.825rem',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '0.5rem',
  },
  value: {
    fontSize: '1rem',
    marginBottom: '1rem',
  },
})

export const DatePaymentInfo = ({ record }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [event, setEvent] = useState()
  const [owner, setOwner] = useState()
  const [numberOfPayments, setNumberOfPayments] = useState()
  useEffect(() => {
    if (record) {
      dataProvider.getOne('event', { id: record.eventID }).then(({ data }) => {
        setEvent(data)
        dataProvider
          .getOne('participant', { id: data.ownerId })
          .then(({ data }) => {
            setOwner(data)
          })
      })
      dataProvider
        .getList('participant_date', {
          pagination: { page: 1, perPage: 0 },
          sort: { field: 'participantID', order: 'DESC' },
          filter: { dateID: record && record.id },
        })
        .then(({ data }) => {
          setNumberOfPayments(
            data.reduce(
              (num, date) =>
                date.payment_data && date.payment_data.payment_status === 'paid'
                  ? num + 1
                  : num,
              0
            )
          )
        })
    }
  }, [record, dataProvider])
  if (record) {
    return (
      <Paper className={classes.container} elevation={2}>
        <Typography variant="h6" gutterBottom>
          Betalingen
        </Typography>
        {event && event.cost ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Aantal betalingen</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {numberOfPayments}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prijs</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {parseFloat(event.cost).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Totaal ontvangen</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {(parseFloat(event.cost) * numberOfPayments).toLocaleString(
                    undefined,
                    {
                      style: 'currency',
                      currency: 'EUR',
                    }
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fee</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {(0.5).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Totale fee</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {(0.5 * numberOfPayments).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.boldCell}>Uitbetalen</TableCell>
                <TableCell
                  className={classnames(
                    classes.boldCell,
                    classes.rightAlignedCell
                  )}>
                  {(
                    parseFloat(event.cost) * numberOfPayments -
                    0.5 * numberOfPayments
                  ).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Organisator info
          </Typography>
        </Box>

        <Box className={classes.label}>Naam</Box>
        <Box className={classes.value}>
          {owner && `${owner.firstName} ${owner.lastName}`}
        </Box>
        <Box className={classes.label}>IBAN</Box>
        <Box className={classes.value}>{owner && owner.bankAccountNumber}</Box>
      </Paper>
    )
  }
  return null
}
