import * as React from 'react'
import { SimpleForm, Edit, TextInput, BooleanInput } from 'react-admin'

export const ParticipantEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <TextInput source="name" />
          <TextInput source="bankAccountNumber" />
          <TextInput source="bankAccountName" />
          <TextInput source="avatarUrl" />
          <BooleanInput source="newsletterGeneral" label="Algemene e-mail" />
          <BooleanInput source="newsletterActivities" label="Activiteiten e-mail" />
          <BooleanInput source="commentsUpdate" label="Prikbordberichten e-mail" />
      </SimpleForm>
  </Edit>
);